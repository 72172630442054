import * as client_hooks from '../../../../_commons/hooks-client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65')
];

export const server_loads = [0,2,4,6,7];

export const dictionary = {
		"/(app)": [~8,[2],[3]],
		"/(app)/companies": [9,[2],[3]],
		"/(app)/companies/[companyId=guid]/aides": [~11,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/aides/[slug]": [~12,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/audit/[requestId]": [~13,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/files": [~14,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/newsletters/[...newsletterId]/results/[newsletterResultId]": [~16,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/newsletters/[...newsletterId]": [~15,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/notes": [~17,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/potential": [~18,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports": [19,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/roadmap/[...roadmapId]/aide/[slug]": [~23,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/roadmap/[...roadmapId]/review": [~24,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/roadmap/[...roadmapId]/settings": [~25,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/roadmap/[...roadmapId]": [~22,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/[requestId]": [~20,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/reports/[requestId]/settings": [~21,[2,4],[3]],
		"/(app)/companies/[companyId=guid]/settings": [~26,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/settings/billing": [27,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/settings/company": [28,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/settings/experts": [29,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/settings/team": [~30,[2,4,5],[3]],
		"/(app)/companies/[companyId=guid]/[...requestId]": [~10,[2,4],[3]],
		"/(app)/faqs": [31,[2],[3]],
		"/(app)/faqs/[destination]": [~32,[2],[3]],
		"/(app)/firms": [~33,[2,6],[3]],
		"/(app)/firms/experts": [39,[2,6],[3]],
		"/(app)/firms/experts/[expertId]": [40,[2,6],[3]],
		"/(app)/firms/offers": [~41,[2,6],[3]],
		"/(app)/firms/offers/edit/[...offerId]": [~42,[2,6],[3]],
		"/(app)/firms/opportunities": [43,[2,6],[3]],
		"/(app)/firms/opportunities/[prospectAdId=guid]": [~44,[2,6],[3]],
		"/(app)/firms/opportunities/[prospectAdId=guid]/contacts/[...messageId]": [45,[2,6],[3]],
		"/(app)/firms/[id]": [~34,[2,6,7],[3]],
		"/(app)/firms/[id]/logs": [35,[2,6,7],[3]],
		"/(app)/firms/[id]/missions": [36,[2,6,7],[3]],
		"/(app)/firms/[id]/stats": [~37,[2,6,7],[3]],
		"/(app)/firms/[id]/users": [38,[2,6,7],[3]],
		"/(app)/forms": [46,[2],[3]],
		"/(app)/forms/supporting-documents": [48,[2],[3]],
		"/(app)/forms/variables/[...id]": [49,[2],[3]],
		"/(app)/forms/[id]": [47,[2],[3]],
		"/(app)/grants": [~50,[2],[3]],
		"/(app)/grants/archived": [~52,[2],[3]],
		"/(app)/grants/check-required": [~53,[2],[3]],
		"/(app)/grants/duplicates": [~54,[2],[3]],
		"/(app)/grants/duplicates/[id]": [~55,[2],[3]],
		"/(app)/grants/financers": [~56,[2],[3]],
		"/(app)/grants/preview/[slug]": [~57,[2],[3]],
		"/(app)/grants/robots": [~58,[2],[3]],
		"/(app)/grants/robots/[scraperId]": [~59,[2],[3]],
		"/(app)/grants/[id]": [~51,[2],[3]],
		"/(auth)/info": [~64],
		"/(app)/settings/profile": [60,[2],[3]],
		"/(app)/settings/security": [61,[2],[3]],
		"/(app)/settings/users": [62,[2],[3]],
		"/(auth)/signin": [~65],
		"/(app)/stats": [~63,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';